import { Card, Col, DatePicker, Row, Space, Table, Tag, Typography, Statistic } from "antd";
import { ColumnsType } from "antd/lib/table";
import gql from "graphql-tag";
import moment from "moment";
import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks";
import { OrderInfo } from "../Finance/OrderInfo";
import * as g from "./__generated__/SearchPromoPayments";

const SEARCH_PAYMENTS = gql`
  query SearchPromoPayments($id: ID!, $input: SearchPromoPaymentsInput!) {
    searchPromoPayments(id: $id, input: $input) {
      stats {
        gateway
        total
        count
      }
      payments {  
        data {
            status
            amount
            balance
            gateway
            date
            source
            sourceName
            description
            user
            userLogin
        }
        page
        pageSize
        total
        }
    }
  }
`;

const { RangePicker } = DatePicker;

const renderStatus = (record: g.SearchPromoPayments_searchPromoPayments_payments_data) => {
  if (record.status === "success") {
    return <Tag color="success">success</Tag>;
  }
  return <Tag color="error">failed</Tag>;
};

const renderAmount = (record: g.SearchPromoPayments_searchPromoPayments_payments_data) => {
  return (
    <Typography.Text
      type={(record.amount < 0) ? "danger" : "success"}
    >
      {formatCurrency(record.amount / 100)}
    </Typography.Text>
  );
};

const renderDescription = (text: string) => {
  const orderID = text.match(/Order ([0-9a-fA-F]{24})/);
  if (orderID) {
    return (
      <Space>
        {text}
        <OrderInfo id={orderID[1]} />
      </Space>
    );
  }
  return text;
};

const columns: ColumnsType<g.SearchPromoPayments_searchPromoPayments_payments_data> = [
  {
    key: "status",
    title: "Status",
    render: renderStatus,
  },
  {
    key: "user",
    title: "User",
    render: (record) => record.userLogin ? <Link to={`/users/${record.user}`}>{record.userLogin}</Link> : "-",
  },
  {
    key: "source",
    title: "Source",
    render: (record) => record.sourceName === "system" ? record.sourceName : <Link to={`/admins/${record.source}`}>{record.sourceName}</Link>,
  },
  {
    key: "description",
    title: "Description",
    render: (record) => renderDescription(record.description),
  },
  {
    key: "date",
    title: "Date",
    render: (record) => formatDate(record.date),
  },
  {
    dataIndex: "gateway",
    title: "Gateway",
  },
  {
    key: "amount",
    title: "Amount",
    align: "right",
    render: renderAmount,
  },
  {
    key: "balance",
    title: "Balance",
    align: "right",
    render: (record) => formatCurrency(record.balance / 100),
  },
];

interface PaymentsState {
  from: Date
  to: Date
  page: number
  pageSize: number
}

const initialState: PaymentsState = {
  from: moment().startOf("month").toDate(),
  to: moment().endOf("month").toDate(),
  page: 1,
  pageSize: 20,
};

const paymentsReducer = (
  state: PaymentsState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

interface PromoPaymentsProps {
  id: string;
}

export const PromoPayments = (props: PromoPaymentsProps) => {
  const [state, dispatch] = useReducer(paymentsReducer, initialState);

  const result = useQuery<g.SearchPromoPayments, g.SearchPromoPaymentsVariables>(SEARCH_PAYMENTS, {
    fetchPolicy: "no-cache",
    variables: {
      id: props.id,
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize,
      },
    },
  });

  return (
    <Card title="Payments">
      <Row gutter={24}>
        <Col span={12}>
          <RangePicker
            allowClear={false}
            showTime={true}
            value={[moment(state.from), moment(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: dates })}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <Statistic 
            title="Total"
            value={(result.data?.searchPromoPayments.stats.reduce((sum, stat) => sum + stat.total, 0) ?? 0) / 100}
            precision={2}
            prefix="$"
          />
        </Col>
        <Col span={6}>
          <Statistic 
            title="Total Count"
            value={result.data?.searchPromoPayments.stats.reduce((sum, stat) => sum + stat.count, 0) ?? 0}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        {result.data?.searchPromoPayments.stats.map((stat) => (
          <Col key={stat.gateway} span={6}>
            <Statistic 
              title={stat.gateway}
              value={stat.total / 100}
              precision={2}
              prefix="$"
              suffix={`(${stat.count})`}
            />
          </Col>
        ))}
      </Row>
      <br />
      <Table
        size="middle"
        rowKey={record => record.date}
        loading={result.loading}
        columns={columns}
        dataSource={result.data?.searchPromoPayments.payments.data}
        pagination={{
          showSizeChanger: true,
          current: state.page,
          pageSize: state.pageSize,
          total: result.data?.searchPromoPayments.payments.total,
        }}
        onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />
    </Card>
  );
};