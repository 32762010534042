import { Spin, Card, Space, Typography, Form, Input, Button, Tag, message } from "antd";
import gql from "graphql-tag";
import React from "react";
import { useQuery, useMutation } from "../../hooks/apollo";
import * as g from "./__generated__/BonusWithdrawalRequest";
import * as gr from "./__generated__/ResolveBonusWithdrawalRequest";
import { useParams, Link } from "react-router-dom";
import { Date } from "../Date";
import { CopyOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatCurrency } from "../../common/formatter";

const GET_WITHDRAWAL_REQUEST = gql`
  query BonusWithdrawalRequest($id: ID!) {
    bonusWithdrawalRequest(id: $id) {
      id
      userId
      userLogin
      amount
      wallet
      network
      status
      comment
      adminNote
      createdAt
      updatedAt
    }
  }
`;

const RESOLVE_WITHDRAWAL_REQUEST = gql`
  mutation ResolveBonusWithdrawalRequest($id: ID!, $action: String!, $input: ResolveBonusWirthdrawalRequestInput!) {
    resolveBonusWithdrawalRequest(id: $id, action: $action, input: $input) {
      id
      userId
      userLogin
      amount
      wallet
      network
      status
      comment
      adminNote
      createdAt
      updatedAt
    }
  }
`;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const renderStatus = (status: string) => {
  switch (status) {
    case "pending":
      return <Tag color="orange">{status}</Tag>;
    case "approved":
      return <Tag color="green">{status}</Tag>;
    case "rejected":
      return <Tag color="red">{status}</Tag>;
    default:
      return <Tag>{status}</Tag>;
  }
};

export const BonusWithdrawal = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { loading, data } = useQuery<g.BonusWithdrawalRequest>(GET_WITHDRAWAL_REQUEST, {
    variables: { id },
  });

  const [resolveBonusWithdrawalRequest] = useMutation<gr.ResolveBonusWithdrawalRequest>(RESOLVE_WITHDRAWAL_REQUEST);

  if (loading) {
    return <Spin />;
  }

  const handleAction = async (action: string) => {
    const values = await form.validateFields();
    await resolveBonusWithdrawalRequest({
      variables: {
        id,
        action,
        input: {
          comment: values.comment,
          adminNote: values.adminNote,
        },
      },
    });
  };
  
  return (
    <Card title="Bonus Withdrawal Request">
      <Form
        {...formLayout}
        form={form}
        initialValues={data?.bonusWithdrawalRequest}
      >
        <Form.Item label="User">
          <Link to={`/users/${data?.bonusWithdrawalRequest.userId}`}>
            {data?.bonusWithdrawalRequest.userLogin}
          </Link>
        </Form.Item>

        <Form.Item label="Amount">
          <Typography.Text>
            {formatCurrency(data?.bonusWithdrawalRequest.amount! / 100)}
          </Typography.Text>
        </Form.Item>

        <Form.Item label="Wallet">
          <Space>
            <Typography.Text>{data?.bonusWithdrawalRequest.wallet}</Typography.Text>
            <CopyToClipboard
              text={data?.bonusWithdrawalRequest.wallet || ""}
              onCopy={() => message.info("Wallet address copied")}
            >
              <Button type="text" icon={<CopyOutlined />} />
            </CopyToClipboard>
          </Space>
        </Form.Item>

        <Form.Item label="Network">
          <Typography.Text>{data?.bonusWithdrawalRequest.network}</Typography.Text>
        </Form.Item>

        <Form.Item label="Status">
          {renderStatus(data?.bonusWithdrawalRequest.status || "")}
        </Form.Item>

        <Form.Item label="Created At">
          <Date date={data?.bonusWithdrawalRequest.createdAt} />
        </Form.Item>

        <Form.Item label="Updated At">
          <Date date={data?.bonusWithdrawalRequest.updatedAt} />
        </Form.Item>

        <Form.Item
          name="comment"
          label="Comment"
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="adminNote"
          label="Admin Note"
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Space>
            <Button
              onClick={() => handleAction("update")}
            >
              Update
            </Button>
            <Button
              type="primary"
              onClick={() => handleAction("approve")}
            >
              Approve
            </Button>
            <Button
              danger
              onClick={() => handleAction("reject")}
            >
              Reject
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};