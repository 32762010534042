import { Card, Col, Form, Input, Row, Switch } from "antd";
import gql from "graphql-tag";
import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import { PromoEvents } from "./Events";
import { PromoStats } from "./Stats";
import * as gd from "./__generated__/DeletePromo";
import * as g from "./__generated__/Promo";
import * as gu from "./__generated__/UpdatePromo";
import { PromoPayments } from "./Payments";
const GET_PROMO = gql`
  query Promo($id: ID!) {
    promo(id: $id) {
      id
      name
      token
      active
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_PROMO = gql`
  mutation UpdatePromo($id: ID!, $input: PromoUpdateInput!) {
    updatePromo(id: $id, input: $input) {
      id
      name
      token
      active
      createdAt
      updatedAt
    }
  }
`;

const DELETE_PROMO = gql`
  mutation DeletePromo($id: ID!) {
    deletePromo(id: $id)
  }
`;

interface PromoEditFields {
  name: string;
  token: string;
  active: boolean;
}

export const PromoEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<PromoEditFields>();

  const { loading, data } = useQuery<g.Promo, g.PromoVariables>(GET_PROMO, {
    variables: { id },
  });

  const [updatePromo] = useMutation<gu.UpdatePromo, gu.UpdatePromoVariables>(UPDATE_PROMO, {
    okText: i18n.t("promo:Promo campaign updated"),
    refetchQueries: ["Promo"],
  });

  const [deletePromo] = useMutation<gd.DeletePromo, gd.DeletePromoVariables>(DELETE_PROMO, {
    okText: i18n.t("promo:Promo campaign deleted"),
    onCompleted: (_) => {
      history.push("/promos");
    }
  });

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={12}>
          <Card title={data?.promo.name}>
            <EditForm
              name="edit-promo"
              deleteTitle={i18n.t("user:Delete promo?")}
              loading={loading}
              form={form}
              initialValues={data?.promo}
              onSave={(values) => updatePromo({ variables: { id, input: values } })}
              onDelete={() => deletePromo({ variables: { id } })}
            >
              <Form.Item
                name="name"
                label={i18n.t("promo:Name")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="token"
                label={i18n.t("promo:Token")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="active"
                label="Active"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="url"
                label="Registration URL"
              >
                <a href={`https://my.ltesocks.io/#/register?promo=${data?.promo.token}`}>{`https://my.ltesocks.io/#/register?promo=${data?.promo.token}`}</a>
              </Form.Item>
            </EditForm>
          </Card>
        </Col>
        <Col span={12}>
          <PromoStats id={id} />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={24}>
          <PromoEvents id={id} />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={24}>
          <PromoPayments id={id} />
        </Col>
      </Row>
    </Fragment >
  );
};
