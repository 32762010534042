import gql from "graphql-tag";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Switch, Form, Input, Modal, message, Card, Row, Col, Space, Statistic, InputNumber, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { useParams, Link} from "react-router-dom";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch, SearchInput } from "../../hooks/search";
import * as gs from "./__generated__/SearchReferrerToken";
import i18n from "../../common/i18n";
import { formatCurrency } from "../../common/formatter";

type ReferrerToken = gs.SearchReferrerToken_searchReferrerToken_data;

interface ListContainerProps<T> {
  create?: React.ReactNode;
  search: SearchInput;
  columns: ColumnsType<T>;
  data?: T[];
  total?: number;
  loading?: boolean;
  onSearch: (filter: string) => void;
  onTableChange: (pagination: any, filters: any, sorter: any) => void;
  summary?: (pageData: T[]) => React.ReactNode;
}

interface RouteParams {
  id: string;
}

interface SearchReferrerTokenVariables {
  userId: string;
  input: SearchInput;
}

const GET_USER_REFERRER_SETTINGS = gql`
  query GetUserReferrerSettings($id: ID!) {
    user(id: $id) {
      id
      login
      referrerId
      referrerLogin
      referrerToken
      referrerSettings {
        enabled
        percent
        gateways
      }
    }
  }
`;

const UPDATE_USER_REFERRER_SETTINGS = gql`
  mutation UpdateUserReferrerSettings($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      id
      login
      referrerId
      referrerLogin
      referrerToken
      referrerSettings {
        enabled
        percent
        gateways
      }
    }
  }
`;

const SEARCH_REFERRER_TOKEN = gql`
  query SearchReferrerToken($userId: String, $input: SearchInput!) {
    searchReferrerToken(userId: $userId, input: $input) {
      data {
        id
        name
        token
        active
        views
        registrations
        userId
        userLogin
        totalBonuses
        totalDeposits
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const CREATE_REFERRER_TOKEN = gql`
  mutation CreateReferrerToken($name: String!, $userId: String!) {
    createReferrerToken(name: $name, userId: $userId) {
      id
      name
      token
      active
      views
      registrations
      userId
      userLogin
      createdAt
      updatedAt
    }
  }
`;  

const UPDATE_REFERRER_TOKEN = gql`
  mutation UpdateReferrerToken($id: ID!, $input: ReferrerTokenUpdateInput!) {
    updateReferrerToken(id: $id, input: $input) {
      id
      name
      token
      active
      views
      registrations
      userId
      userLogin
      createdAt
      updatedAt
    }
  }
`;

const CreateButton = ({ onClick }: { onClick: () => void }) => (
  <Button type="primary" onClick={onClick}>
    {i18n.t("referrer:Create Token")}
  </Button>
);

const useReferrerSearch = (userId: string) => {
  const searchHook = useSearch<gs.SearchReferrerToken>(SEARCH_REFERRER_TOKEN);
  
  return {
    ...searchHook,
    result: useQuery<gs.SearchReferrerToken>(SEARCH_REFERRER_TOKEN, {
      variables: {
        userId,
        input: searchHook.search,
      },
      fetchPolicy: "no-cache",
    }),
  };
};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const ReferralListContainer = (props: {
  create: React.ReactNode;
  search: SearchInput;
  columns: ColumnsType<ReferrerToken>;
  data?: ReferrerToken[];
  total?: number;
  loading: boolean;
  onSearch: (filter: string) => void;
  onTableChange: (pagination: any, filters: any, sorter: any) => void;
}) => {
  const totalBonuses = (props.data || []).reduce((sum: number, current: ReferrerToken) => sum + (current.totalBonuses || 0), 0);
  const totalDeposits = (props.data || []).reduce((sum: number, current: ReferrerToken) => sum + (current.totalDeposits || 0), 0);

  return (
    <div>
      <ListContainer {...props} />
      <Table
        pagination={false}
        showHeader={false}
        dataSource={[{ id: "summary" }]}
        columns={[
          { dataIndex: "empty1", render: () => null, width: "50%" },
          { 
            dataIndex: "bonuses",
            render: () => (
              <strong>{i18n.t("referrer:Total Bonuses")}: {formatCurrency(totalBonuses / 100)}</strong>
            ),
            width: "25%"
          },
          {
            dataIndex: "deposits",
            render: () => (
              <strong>{i18n.t("referrer:Total Deposits")}: {formatCurrency(totalDeposits / 100)}</strong>
            ),
            width: "25%"
          }
        ]}
      />
    </div>
  );
};

export const UserReferralProgram = () => {
  const [form] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const { id: userId } = useParams<RouteParams>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingToken, setEditingToken] = useState<ReferrerToken | null>(null);
  const [allowedGateways, setAllowedGateways] = useState([] as string[]);

  const { result, search, updateFilter, updateTable } = useReferrerSearch(userId);

  const { data: referrerData } = useQuery(GET_USER_REFERRER_SETTINGS, {
    variables: { id: userId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setAllowedGateways(data.user.referrerSettings.gateways || []);
      settingsForm.setFieldsValue({
        enabled: data.user.referrerSettings.enabled,
        percent: data.user.referrerSettings.percent,
      });
    }
  });

  const [updateReferrerSettings] = useMutation(UPDATE_USER_REFERRER_SETTINGS, {
    onCompleted: () => {
      message.success(i18n.t("referrer:Settings updated successfully"));
    },
    onError: (error) => {
      message.error(error.message);
    }
  });

  const toggleGateway = (gateway: string) => {
    if (allowedGateways.includes(gateway)) {
      setAllowedGateways(allowedGateways.filter(v => v !== gateway));
    } else {
      setAllowedGateways([...allowedGateways, gateway]);
    }
  };

  const handleUpdateSettings = async (values: any) => {
    try {
      await updateReferrerSettings({
        variables: {
          id: userId,
          input: {
            referrerSettings: {
              enabled: values.enabled,
              percent: values.percent,
              gateways: allowedGateways,
            }
          }
        }
      });
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const [createToken] = useMutation(CREATE_REFERRER_TOKEN, {
    onCompleted: () => {
      message.success(i18n.t("referrer:Token created successfully"));
      setIsModalVisible(false);
      form.resetFields();
      result.refetch();
    },
    onError: (error) => {
      message.error(error.message);
    }
  });

  const [updateToken] = useMutation(UPDATE_REFERRER_TOKEN, {
    onCompleted: () => {
      message.success(i18n.t("referrer:Token updated successfully"));
      setIsModalVisible(false);
      setEditingToken(null);
      form.resetFields();
      result.refetch();
    },
    onError: (error) => {
      message.error(error.message);
    }
  });

  const columns: ColumnsType<ReferrerToken> = [
    {
      title: i18n.t("referrer:Name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: i18n.t("referrer:Token"),
      dataIndex: "token",
      sorter: true,
    },
    {
      title: i18n.t("referrer:Active"),
      dataIndex: "active",
      render: (active: boolean) => (
        <Switch size="small" checked={active} disabled />
      ),
    },
    {
      title: i18n.t("referrer:Views"),
      dataIndex: "views",
    },
    {
      title: i18n.t("referrer:Registrations"),
      dataIndex: "registrations",
      render: (text: number, record: ReferrerToken) => (
        <Link to={`/users/list?filter=ref:${record.token}`}>{text}</Link>
      ),
    },
    {
      title: i18n.t("referrer:Total Bonuses"),
      dataIndex: "totalBonuses",
      render: (text: number) => formatCurrency(text / 100),
    },
    {
      title: i18n.t("referrer:Total Deposits"),
      dataIndex: "totalDeposits",
      render: (text: number) => formatCurrency(text / 100),
    },
    {
      title: i18n.t("referrer:Created At"),
      dataIndex: "createdAt",
      sorter: true,
      render: (text: string) => <Date date={text} />,
    },
    {
      title: i18n.t("referrer:Updated At"),
      dataIndex: "updatedAt",
      sorter: true,
      render: (text: string) => <Date date={text} />,
      defaultSortOrder: "descend" as SortOrder,
    },
    {
      title: i18n.t("referrer:Actions"),
      key: "actions",
      render: (_: unknown, record: ReferrerToken) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          {i18n.t("referrer:Edit")}
        </Button>
      ),
    },
  ];

  const handleEdit = (token: ReferrerToken) => {
    setEditingToken(token);
    form.setFieldsValue({
      name: token.name,
      active: token.active,
    });
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      if (editingToken) {
        await updateToken({
          variables: {
            id: editingToken.id,
            input: values,
          },
        });
      } else {
        await createToken({
          variables: {
            name: values.name,
            userId,
          },
        });
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingToken(null);
    form.resetFields();
  };

  return (
    <>
      <Card>
        <Card.Grid style={{ width: "50%", outline: "none" }} hoverable={false}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space style={{ width: "100%" }} direction="vertical">
              <h3>{i18n.t("referrer:Referral Program Settings")}</h3>
              <Form
                {...formLayout}
                form={settingsForm}
                name="edit-referral"
                onFinish={handleUpdateSettings}
              >
                <Form.Item
                  label={i18n.t("referrer:Status")}
                  name="enabled"
                  valuePropName="checked"
                >
                  <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
                </Form.Item>

                <Form.Item
                  label={i18n.t("referrer:Percent")}
                  name="percent"
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                  />
                </Form.Item>

                <br />

                <Form.Item
                  label={i18n.t("referrer:Allowed Gateways")}
                >
                  <Space direction="vertical">
                    <Space>
                      <Switch checkedChildren="wayforpay" unCheckedChildren="wayforpay" checked={allowedGateways.includes("wfp")} onChange={() => toggleGateway("wfp")} />
                      <Switch checkedChildren="any.money" unCheckedChildren="any.money" checked={allowedGateways.includes("anymoney")} onChange={() => toggleGateway("anymoney")} />
                      <Switch checkedChildren="Binance Pay" unCheckedChildren="Binance Pay" checked={allowedGateways.includes("binance")} onChange={() => toggleGateway("binance")} />
                    </Space>
                    <Space>
                      <Switch checkedChildren="Whitepay" unCheckedChildren="Whitepay" checked={allowedGateways.includes("whitepay")} onChange={() => toggleGateway("whitepay")} />
                      <Switch checkedChildren="Stripe" unCheckedChildren="Stripe" checked={allowedGateways.includes("stripe")} onChange={() => toggleGateway("stripe")} />
                      <Switch checkedChildren="iPay" unCheckedChildren="iPay" checked={allowedGateways.includes("ipay")} onChange={() => toggleGateway("ipay")} />
                      <Switch checkedChildren="PSPark" unCheckedChildren="PSPark" checked={allowedGateways.includes("pspark")} onChange={() => toggleGateway("pspark")} />
                    </Space>
                  </Space>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    htmlType="submit"
                    type="primary"
                  >
                    {i18n.t("referrer:Update")}
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Space>
        </Card.Grid>

        <Card.Grid style={{ width: "50%", outline: "none" }} hoverable={false}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row gutter={24} justify="center">
              <Col span={12}>
                <Statistic
                  title={i18n.t("referrer:Referrer")}
                  value={referrerData?.user.referrerLogin || String(i18n.t("referrer:No"))}
                  formatter={(value) => 
                    referrerData?.user.referrerLogin ? 
                    <Link to={`/users/${referrerData?.user.referrerId}`}>{value}</Link> : 
                    value
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={i18n.t("referrer:Referrer Token")}
                  value={referrerData?.user.referrerToken || String(i18n.t("referrer:No"))}
                />
              </Col>
            </Row>
          </Space>
        </Card.Grid>
      </Card>

      <Card style={{ marginTop: 24 }}>
        <ReferralListContainer
          create={<CreateButton onClick={() => setIsModalVisible(true)} />}
          search={search}
          columns={columns}
          data={result.data?.searchReferrerToken.data}
          total={result.data?.searchReferrerToken.total}
          loading={result.loading}
          onSearch={updateFilter}
          onTableChange={updateTable}
        />
      </Card>

      <Modal
        title={editingToken ? i18n.t("referrer:Edit Token") : i18n.t("referrer:Create Token")}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label={i18n.t("referrer:Name")}
            rules={[{ required: true, message: i18n.t("referrer:Please input the token name!") }]}
          >
            <Input />
          </Form.Item>
          
          {editingToken && (
            <Form.Item
              name="active"
              label={i18n.t("referrer:Active")}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
