import { ApartmentOutlined, ApiOutlined, AuditOutlined, BankOutlined, ClusterOutlined, DeploymentUnitOutlined, DesktopOutlined, DollarOutlined, FundOutlined, PhoneOutlined, PictureOutlined, ProfileOutlined, UserOutlined, WindowsOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAccess } from "../../hooks";
import styles from "./styles.module.less";

export const Sidebar = () => {
  const { t } = useTranslation("sidebar");
  const location = useLocation();
  const [opened, setOpened] = useState([location.pathname.split("/")[1]]);
  const [collapsed, setCollapsed] = useState(false);
  const selected = location.pathname.split("/").slice(1).join("-");

  const { access } = useAccess("plans", "finance", "banners", "admins", "promos");

  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
      <div className={styles.logo} />
      <Menu theme="dark" openKeys={opened} selectedKeys={[selected]} mode="inline" onOpenChange={(openKeys) => setOpened(openKeys as string[])}>
        <Menu.SubMenu key="users" icon={<UserOutlined />} title={t("Users")}>
          <Menu.Item key="users-list">
            <Link to="/users/list">{t("List")}</Link>
          </Menu.Item>

          <Menu.Item key="users-pending">
            <Link to="/users/pending">{t("Pending")}</Link>
          </Menu.Item>


          <Menu.Item key="users-inactive">
            <Link to="/users/inactive">{t("Inactive")}</Link>
          </Menu.Item>

          <Menu.Item key="users-requests">
            <Link to="/users/requests">{t("Requests")}</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="ports" icon={<ApiOutlined />} title={t("Ports")}>
          <Menu.Item key="ports-list">
            <Link to="/ports/list">{t("List")}</Link>
          </Menu.Item>

          <Menu.Item key="ports-billing">
            <Link to="/ports/billing">{t("Billing")}</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="nodes" icon={<DesktopOutlined />} title={t("Nodes")}>
          <Menu.Item key="nodes-list">
            <Link to="/nodes/list">{t("List")}</Link>
          </Menu.Item>

          <Menu.Item key="nodes-mikrotik">
            <Link to="/nodes/mikrotik">{t("MikroTik")}</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="modems" icon={<PhoneOutlined />} title={t("Modems")}>
          <Menu.Item key="modems-list">
            <Link to="/modems/list">{t("List")}</Link>
          </Menu.Item>

          <Menu.Item key="modems-stuck">
            <Link to="/modems/stuck">{t("Stuck")}</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="pools" icon={<DeploymentUnitOutlined />}>
          <Link to="/pools">{t("Pools")}</Link>
        </Menu.Item>


        <Menu.SubMenu key="routers" icon={<ClusterOutlined />} title={t("Routers")}>
          <Menu.Item key="routers-restrictions">
            <Link to="/routers/restrictions">{t("Restrictions")}</Link>
          </Menu.Item>

          <Menu.Item key="routers-log">
            <Link to="/routers/log">{t("Access Log")}</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="vpn" icon={<ApartmentOutlined />}>
          <Link to="/vpn">{t("OpenVPN")}</Link>
        </Menu.Item>

        <Menu.Item key="signatures" icon={<WindowsOutlined />}>
          <Link to="/signatures">{t("Signatures")}</Link>
        </Menu.Item>

        {access["plans"] && <Menu.Item key="plans" icon={<DollarOutlined />}>
          <Link to="/plans">{t("Plans")}</Link>
        </Menu.Item>}

        {access["finance"] && <Menu.SubMenu key="finance" icon={<BankOutlined />} title={t("Finance")}>
          <Menu.Item key="finance-orders">
            <Link to="/finance/orders">{t("Orders")}</Link>
          </Menu.Item>

          <Menu.Item key="finance-topup">
            <Link to="/finance/topup">{t("Top Up Summary")}</Link>
          </Menu.Item>

          <Menu.Item key="finance-bonus-withdrawal">
            <Link to="/finance/bonus-withdrawal">{t("Bonus Withdrawal")}</Link>
          </Menu.Item>

          <Menu.Item key="finance-referral-bonuses">
            <Link to="/finance/referral-bonuses">{t("Referral Bonuses")}</Link>
          </Menu.Item>
        </Menu.SubMenu>}

        {access["banners"] && <Menu.Item key="banners" icon={<PictureOutlined />}>
          <Link to="/banners">{t("Banners")}</Link>
        </Menu.Item>}

        {access["promos"] && <Menu.Item key="promos" icon={<FundOutlined />}>
          <Link to="/promos">{t("Promo Campaigns")}</Link>
        </Menu.Item>}

        {access["admins"] && <Menu.SubMenu key="admins-selector" icon={<AuditOutlined />} title={t("Administrators")}>
          <Menu.Item key="admins">
            <Link to="/admins">{t("List")}</Link>
          </Menu.Item>

          <Menu.Item key="roles">
            <Link to="/roles">{t("Roles")}</Link>
          </Menu.Item>
        </Menu.SubMenu>}

        <Menu.Item key="logs" icon={<ProfileOutlined />}>
          <Link to="/logs">{t("Logs")}</Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider >
  );
};
