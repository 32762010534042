import { Card, Col, DatePicker, Row, Button, Space, Table, Breadcrumb, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import moment from "moment";
import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks";

const { RangePicker } = DatePicker;

const GET_REFERRER_BONUSES = gql`
  query GetReferrerBonuses($from: Time!, $to: Time!) {
    referrerBonuses(from: $from, to: $to) {
      userId
      userLogin
      bonuses
      deposits
      tokens {
        token
        tokenId
        tokenName
        bonuses
        deposits
        referees {
          userId
          userLogin
          bonuses
          deposits
          payments {
            user
            userLogin
            source
            sourceName
            gateway
            status
            amount
            balance
            account
            date
            description
            referee {
              userId
              userLogin
              token
              tokenId
              tokenName
              percent
              amount
            }
          }
        }
      }
    }
  }
`;

interface ReferrerBonusesState {
  from: Date;
  to: Date;
  view: "referrers" | "tokens" | "referees";
  selectedReferrer?: {
    userId: string;
    userLogin: string;
  };
  selectedToken?: {
    token: string;
    tokenName: string;
  };
}

const initialState: ReferrerBonusesState = {
  from: moment().startOf("month").toDate(),
  to: moment().endOf("month").toDate(),
  view: "referrers",
};

interface ReferrerBonusesAction {
  type: "DATES" | "VIEW_TOKENS" | "VIEW_REFEREES" | "BACK";
  data: any;
}

const bonusesReducer = (
  state: ReferrerBonusesState,
  action: ReferrerBonusesAction
): ReferrerBonusesState => {
  switch (action.type) {
    case "DATES":
      return { 
        ...initialState, 
        from: action.data[0], 
        to: action.data[1],
      };
    case "VIEW_TOKENS":
      return { 
        ...initialState,
        from: state.from,
        to: state.to,
        view: "tokens", 
        selectedReferrer: action.data,
      };
    case "VIEW_REFEREES":
      return { 
        ...initialState,
        from: state.from,
        to: state.to,
        view: "referees",
        selectedReferrer: state.selectedReferrer,
        selectedToken: action.data,
      };
    case "BACK":
      if (state.view === "referees") {
        return { 
          ...initialState,
          from: state.from,
          to: state.to,
          view: "tokens",
          selectedReferrer: state.selectedReferrer,
        };
      }
      return { 
        ...initialState,
        from: state.from,
        to: state.to,
      };
    default:
      return state;
  }
};

interface BreadcrumbItem {
  title: string;
  onClick?: () => void;
}

const referrerColumns: ColumnsType<any> = [
  {
    title: "User",
    dataIndex: "userLogin",
    key: "userLogin",
    render: (text, record) => (
      <Link to={`/users/${record.userId}`}>{text}</Link>
    ),
  },
  {
    title: "Bonuses",
    dataIndex: "bonuses",
    key: "bonuses",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
  {
    title: "Deposits",
    dataIndex: "deposits",
    key: "deposits",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
  {
    title: "",
    key: "action",
    width: 50,
    align: "right",
    render: () => <RightOutlined style={{ color: "#1890ff" }} />,
  },
];

const refereeColumns: ColumnsType<any> = [
  {
    title: "Referee",
    dataIndex: "userLogin",
    key: "userLogin",
    render: (text, record) => (
      <Link to={`/users/${record.userId}`}>{text}</Link>
    ),
  },
  {
    title: "Bonuses",
    dataIndex: "bonuses",
    key: "bonuses",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
  {
    title: "Deposits",
    dataIndex: "deposits",
    key: "deposits",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
  {
    title: "",
    key: "action",
    width: 50,
    align: "right",
    render: () => <RightOutlined style={{ color: "#1890ff" }} />,
  },
];

const tokenColumns: ColumnsType<any> = [
  {
    title: "Token Name",
    dataIndex: "tokenName",
    key: "tokenName",
  },
  {
    title: "Token",
    dataIndex: "token",
    key: "token",
  },
  {
    title: "Bonuses",
    dataIndex: "bonuses",
    key: "bonuses",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
  {
    title: "Deposits",
    dataIndex: "deposits",
    key: "deposits",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
  {
    title: "",
    key: "action",
    width: 50,
    align: "right",
    render: () => <RightOutlined style={{ color: "#1890ff" }} />,
  },
];

const paymentColumns: ColumnsType<any> = [
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   render: (text) => (
  //     <Tag color={text === "success" ? "success" : "error"}>{text}</Tag>
  //   ),
  // },
  // {
  //   title: "Source",
  //   dataIndex: "sourceName",
  //   key: "sourceName",
  //   render: (text, record) => text === "system" ? text : <Link to={`/admins/${record.source}`}>{text}</Link>,
  // },
  {
    title: "Referee",
    key: "referee",
    render: (text, record) => (
      <Link to={`/users/${record.referee.userId}`}>{record.referee.userLogin}</Link>
    ),
  },
  {
    title: "Payment",
    key: "payment",
    render: (text, record) => (
      formatCurrency(record.referee.amount / 100)
    ),
  },
  {
    title: "Percent",
    key: "percent",
    render: (text, record) => (
      `${record.referee.percent}%`
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => formatDate(text),
  },
  {
    title: "Gateway",
    dataIndex: "gateway",
    key: "gateway",
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    render: (text) => (
      <Tag color={text === "regular" ? "blue" : "yellow"}>{text}</Tag>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    render: (value) => (
      <Typography.Text type={value < 0 ? "danger" : "success"}>
        {formatCurrency(value / 100)}
      </Typography.Text>
    ),
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    align: "right",
    render: (value) => formatCurrency(value / 100),
  },
];

const getParentRow = (state: ReferrerBonusesState, data: any) => {
  if (state.view === "tokens") {
    const referrer = data?.referrerBonuses.find((r: any) => r.userId === state.selectedReferrer?.userId);
    if (!referrer) return null;

    return (
      <Card size="small" style={{ marginBottom: 16 }}>
        <Row>
          <Col span={8}>
            <b>Referrer:</b> <Link to={`/users/${referrer.userId}`}>{referrer.userLogin}</Link>
          </Col>
          <Col span={8}>
            <b>Bonuses:</b> {formatCurrency(referrer.bonuses / 100)}
          </Col>
          <Col span={8}>
            <b>Deposits:</b> {formatCurrency(referrer.deposits / 100)}
          </Col>
        </Row>
      </Card>
    );
  }

  if (state.view === "referees") {
    const referrer = data?.referrerBonuses.find((r: any) => r.userId === state.selectedReferrer?.userId);
    const token = referrer?.tokens.find((t: any) => t.token === state.selectedToken?.token);
    if (!referrer || !token) return null;

    return (
      <Card size="small" style={{ marginBottom: 16 }}>
        <Row>
          <Col span={8}>
            <b>Referrer:</b> <Link to={`/users/${referrer.userId}`}>{referrer.userLogin}</Link>
          </Col>
          <Col span={8}>
            <b>Bonuses:</b> {formatCurrency(referrer.bonuses / 100)}
          </Col>
          <Col span={8}>
            <b>Deposits:</b> {formatCurrency(referrer.deposits / 100)}
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={8}>
            <b>Token:</b> <>{token.tokenName} | {token.token}</>
          </Col>
          <Col span={8}>
            <b>Bonuses:</b> {formatCurrency(token.bonuses / 100)}
          </Col>
          <Col span={8}>
            <b>Deposits:</b> {formatCurrency(token.deposits / 100)}
          </Col>
        </Row>
      </Card>
    );
  }

  return null;
};

export const ReferrerBonuses = () => {
  const [state, dispatch] = useReducer(bonusesReducer, initialState);
  const [selectedRefereeId, setSelectedRefereeId] = React.useState<string | null>(null);

  // Reset selectedRefereeId when view changes
  React.useEffect(() => {
    setSelectedRefereeId(null);
  }, [state.view, state.selectedToken]);

  const result = useQuery(GET_REFERRER_BONUSES, {
    fetchPolicy: "no-cache",
    variables: {
      from: state.from,
      to: state.to,
    },
  });

  const handleBack = React.useCallback(() => {
    dispatch({ type: "BACK", data: null });
  }, []);

  const handleDateChange = React.useCallback((dates: any) => {
    dispatch({ type: "DATES", data: dates });
  }, []);

  const currentReferrer = React.useMemo(() => 
    result.data?.referrerBonuses.find((r: any) => r.userId === state.selectedReferrer?.userId),
    [result.data, state.selectedReferrer]
  );

  const currentToken = React.useMemo(() => 
    currentReferrer?.tokens.find((t: any) => t.token === state.selectedToken?.token),
    [currentReferrer, state.selectedToken]
  );

  const allTokenPayments = React.useMemo(() => {
    if (!currentReferrer) return [];
    return currentReferrer.tokens
      .flatMap((t: any) => t.referees
        .flatMap((r: any) => r.payments || []))
      .sort((a: { date: string }, b: { date: string }) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      );
  }, [currentReferrer]);

  const currentReferees = React.useMemo(() => 
    currentToken?.referees || [],
    [currentToken]
  );

  const filteredPayments = React.useMemo(() => {
    const payments = currentReferees
      .flatMap((referee: any) => referee.payments || [])
      .filter((payment: any) => !selectedRefereeId || payment.referee.userId === selectedRefereeId)
      .sort((a: { date: string }, b: { date: string }) => 
        new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    return payments;
  }, [currentReferees, selectedRefereeId]);

  const handleRefereeClick = React.useCallback((record: any) => {
    setSelectedRefereeId(
      selectedRefereeId === record.userId ? null : record.userId
    );
  }, [selectedRefereeId]);

  const handleTokenClick = React.useCallback((record: any) => {
    dispatch({ 
      type: "VIEW_REFEREES", 
      data: { token: record.token, tokenName: record.tokenName }
    });
  }, []);

  const handleReferrerClick = React.useCallback((record: any) => {
    dispatch({ 
      type: "VIEW_TOKENS", 
      data: { userId: record.userId, userLogin: record.userLogin }
    });
  }, []);

  const breadcrumbItems = React.useMemo(() => {
    const items: BreadcrumbItem[] = [
      {
        title: "Referral Bonuses",
        onClick: state.view !== "referrers" ? handleBack : undefined,
      },
    ];

    if (state.view === "tokens" || state.view === "referees") {
      const referrerLogin = state.selectedReferrer?.userLogin;
      if (referrerLogin) {
        items.push({
          title: referrerLogin,
          onClick: state.view === "referees" ? handleBack : undefined,
        });
      }
    }

    if (state.view === "referees") {
      const tokenName = state.selectedToken?.tokenName;
      if (tokenName) {
        items.push({
          title: tokenName,
        });
      }
    }

    return items;
  }, [state.view, state.selectedReferrer, state.selectedToken, handleBack]);

  const parentRow = React.useMemo(() => 
    getParentRow(state, result.data),
    [state, result.data]
  );

  const getTableConfig = React.useCallback(() => {
    switch (state.view) {
      case "tokens":
        return {
          tables: [
            {
              title: "Tokens",
              columns: tokenColumns,
              dataSource: currentReferrer?.tokens || [],
              onRow: (record: any) => ({
                onClick: () => handleTokenClick(record),
                style: { cursor: "pointer" }
              }),
              summary: () => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={tokenColumns.length}>
                      <Space>
                        <RightOutlined style={{ color: "#1890ff" }} />
                        Click on a row to view referees for this token
                      </Space>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            },
            {
              title: "All Payment History",
              columns: paymentColumns,
              dataSource: allTokenPayments,
            },
          ],
        };
      case "referees":
        return {
          tables: [
            {
              title: "Referees",
              columns: refereeColumns,
              dataSource: currentReferees,
              onRow: (record: any) => ({
                onClick: () => handleRefereeClick(record),
                style: { 
                  cursor: "pointer",
                  backgroundColor: selectedRefereeId === record.userId ? "#f0f0f0" : undefined
                }
              }),
              summary: () => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={refereeColumns.length}>
                      <Space>
                        <RightOutlined style={{ color: "#1890ff" }} />
                        Click on a row to filter payments by referee
                      </Space>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            },
            {
              title: selectedRefereeId 
                ? `Payment History for ${currentReferees.find((r: any) => r.userId === selectedRefereeId)?.userLogin}`
                : "All Payment History",
              columns: paymentColumns,
              dataSource: filteredPayments,
            },
          ],
        };
      default:
        return {
          columns: referrerColumns,
          dataSource: result.data?.referrerBonuses || [],
          onRow: (record: any) => ({
            onClick: () => handleReferrerClick(record),
            style: { cursor: "pointer" }
          }),
          summary: () => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={referrerColumns.length}>
                  <Space>
                    <RightOutlined style={{ color: "#1890ff" }} />
                    Click on a row to view tokens for this referrer
                  </Space>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        };
    }
  }, [
    state.view,
    currentReferrer,
    currentReferees,
    allTokenPayments,
    filteredPayments,
    selectedRefereeId,
    result.data,
    handleTokenClick,
    handleRefereeClick,
    handleReferrerClick
  ]);

  const tableConfig = React.useMemo(() => getTableConfig(), [getTableConfig]);

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space size="middle">
            {state.view !== "referrers" && (
              <Button 
                icon={<LeftOutlined />} 
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Breadcrumb>
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>
                  {item.onClick ? (
                    <Button type="link" onClick={item.onClick} style={{ padding: 0 }}>
                      {item.title}
                    </Button>
                  ) : (
                    item.title
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Space>
        </Col>
        <Col span={12}>
          <RangePicker
            allowClear={false}
            showTime={true}
            value={[moment(state.from), moment(state.to)]}
            onChange={handleDateChange}
          />
        </Col>
      </Row>
      <br />
      {parentRow}
      {tableConfig.tables ? (
        <>
          {tableConfig.tables.map((table, index) => (
            <React.Fragment key={index}>
              <Typography.Title level={4}>{table.title}</Typography.Title>
              <Table
                size="middle"
                rowKey={state.view === "referrers" ? "userId" : state.view === "tokens" ? "token" : index === 0 ? "userId" : "date"}
                loading={result.loading}
                columns={table.columns}
                dataSource={table.dataSource}
                onRow={table.onRow}
                pagination={table.title.includes("Payment History") ? {
                  pageSize: 50,
                  showTotal: (total) => `Total ${total} items`,
                } : false}
                summary={table.summary}
              />
              <br />
            </React.Fragment>
          ))}
        </>
      ) : (
        <Table
          size="middle"
          rowKey={state.view === "referrers" ? "userId" : state.view === "tokens" ? "token" : "userId"}
          loading={result.loading}
          columns={tableConfig.columns}
          dataSource={tableConfig.dataSource}
          onRow={tableConfig.onRow}
          pagination={false}
          summary={tableConfig.summary}
        />
      )}
    </Card>
  );
};
