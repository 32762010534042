import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import gql from "graphql-tag";
import React from "react";
import i18n from "../../common/i18n";
import { Date } from "../../components/Date";
import { ListContainer } from "../../containers/ListContainer";
import { useSearch } from "../../hooks";
import * as g from "./__generated__/SearchBonusWithdrawalRequests";
import { Link } from "react-router-dom";
import { Tag } from "antd";

const SEARCH_BONUS_WITHDRAWAL = gql`
  query SearchBonusWithdrawalRequests($input: SearchInput!) {
    searchBonusWithdrawalRequests(input: $input) {
      data {
        id
        userId
        userLogin
        amount
        wallet
        network
        status
        createdAt
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const columns: ColumnsType<g.SearchBonusWithdrawalRequests_searchBonusWithdrawalRequests_data> = [
  {
    dataIndex: "id",
    title: i18n.t("finance:ID"),
    sorter: true,
    render: text => <Link to={`/finance/bonus-withdrawal/${text}`}>{text}</Link>,
  },
  {
    key: "userId",
    title: i18n.t("finance:User"),
    render: record => <Link to={`/users/${record.userId}`}>{record.userLogin}</Link>,
  },
  {
    dataIndex: "amount",
    title: i18n.t("finance:Amount"),
    render: text => <span>${(text / 100).toFixed(2)}</span>,
  },
  {
    dataIndex: "wallet",
    title: i18n.t("finance:Wallet"),
  },
  {
    dataIndex: "network",
    title: i18n.t("finance:Network"),
  },
  {
    key: "status",
    title: i18n.t("finance:Status"),
    render: record => <Tag color={record.status === "pending" ? "orange" : record.status === "approved" ? "green" : "red"}>{record.status}</Tag>,
    sorter: true,
  },
  {
    dataIndex: "createdAt",
    title: i18n.t("finance:Created At"),
    render: text => <Date date={text} />,
    sorter: true,
  },
  {
    dataIndex: "updatedAt",
    title: i18n.t("finance:Updated At"),
    sorter: true,
    render: text => <Date date={text} />,
    defaultSortOrder: "descend" as SortOrder,
  },
];

export const BonusWithdrawalList = () => {
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchBonusWithdrawalRequests>(SEARCH_BONUS_WITHDRAWAL);

  return (
    <ListContainer
      create={<span></span>}
      search={search}
      columns={columns}
      data={result.data?.searchBonusWithdrawalRequests.data}
      total={result.data?.searchBonusWithdrawalRequests.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};